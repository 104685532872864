import React from "react";
import tw from "twin.macro";
import Testimonial from "./Testimonial";

const TestimonialsWrapper = tw.section`
  max-w-lg mx-auto px-8 md:max-w-screen-xl md:flex md:flex-row md:flex-wrap md:px-6 lg:px-8
`;

const Testimonials = () => (
  <TestimonialsWrapper>
    <Testimonial
      quote="I use Jeevz weekly, for business or social events, and it's the only service I use and trust for all of my transportation needs. I love my preferred drivers and Jeevz delivers."
      author="Carla"
      authorInfo="Jeevz user for 3 years"
      authorBio="https://images.unsplash.com/photo-1573497019940-1c28c88b4f3e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
    />
    <Testimonial
      right
      quote="I am a Jeevz believer and tell everyone about your service, it's fantastic! My preferred Jeevz driver is terrific, I'm very meticulous with my car and trust him implicitly with it."
      author="Alberto"
      authorInfo="Jeevz user for 5 years"
      authorBio="https://images.unsplash.com/photo-1560241804-02b7b1bc9d55?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
    />
  </TestimonialsWrapper>
);

export default Testimonials;
