import React from "react";
import { graphql } from "gatsby";
import { get } from "lodash";
import Layout from "../layouts/layout";
import { RichText } from "prismic-reactjs";
import SEO from "../components/Seo";
import SliceZone from "../components/SliceZone";
import Testimonials from "../components/Testimonials";
import PricingSection from "../components/slices/PricingSection";
import CtaBlock from "../components/CtaBlock";

const LandingPage = ({ data }) => {
  const page = get(data, "prismic.allLanding_pages.edges[0].node");
  if (!page) return null;

  return (
    <Layout>
      <SEO title={RichText.asText(page.page_title)} />
      <SliceZone sliceZone={page.body} trackPage={page.page_title} />
      <Testimonials />
      <PricingSection />
      <CtaBlock cta="Contact Us" ctalink="mailto:support@jeevz.com">
        Questions? Talk to our concierge team today.
      </CtaBlock>
    </Layout>
  );
};

export default LandingPage;

export const query = graphql`
  query LandingPageQuery($uid: String!) {
    prismic {
      allLanding_pages(uid: $uid) {
        edges {
          node {
            _meta {
              uid
            }
            page_title
            body {
              ... on PRISMIC_Landing_pageBodyHeader_block {
                type
                label
                primary {
                  intro__copy
                  page_header
                  header_background
                }
              }
              ... on PRISMIC_Landing_pageBodyFeature_grid {
                type
                label
                primary {
                  headline
                }
                fields {
                  feature_description
                  feature_icon
                  feature_title
                }
              }
              ... on PRISMIC_Landing_pageBodyLifestyle_image {
                type
                label
                primary {
                  headline
                  jumbo_image
                }
              }
              ... on PRISMIC_Landing_pageBodyFeature_strip {
                type
                label
                fields {
                  feature_description
                  feature_icon
                  feature_title
                }
              }
              ... on PRISMIC_Landing_pageBodyFeature_picker__grid {
                type
                label
                primary {
                  headline
                }
                fields {
                  feature {
                    ... on PRISMIC_Feature_tile {
                      feature_icon
                      feature_title
                      feature_description
                    }
                  }
                }
              }
              ... on PRISMIC_Landing_pageBodyFeature_picker__strip {
                type
                label
                fields {
                  feature {
                    ... on PRISMIC_Feature_tile {
                      feature_icon
                      feature_title
                      feature_description
                    }
                  }
                }
              }
              ... on PRISMIC_Landing_pageBodyCovid {
                type
                label
              }
            }
          }
        }
      }
    }
  }
`;
